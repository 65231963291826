/* eslint-disable jsx-a11y/control-has-associated-label */

import "./styles.scss"

import React from "react"

import { useConfig } from "context/config"

// eslint-disable-next-line no-empty-pattern
const ConfigPreview = React.forwardRef(({}, ref) => {
  const { current_wallpaper } = useConfig()

  return (
    <div className="config-sample-preview">
      <div className="config-sample-preview__header-mobile">
        <h1>Próbka tapety</h1>
        <p>
          Zamawiając próbkę masz możliwość sprawdzenia rzeczywistych barw wzoru
          oraz zapoznania się z wybraną teskturą.
          <br />W próbce uchwyciliśmy fragment grafiki, starannie pomniejszony,
          by maksymalnie zaprezentować wybrany wzór.
        </p>
        <p>
          <strong>
            Wybierz wzór, wersję kolorystyczną oraz teksturę materiału.
          </strong>
        </p>
      </div>
      <img ref={ref} src={current_wallpaper?.variation?.image} alt="" />
    </div>
  )
})

export default ConfigPreview
