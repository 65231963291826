import "./styles.scss"
import React from "react"

import Modal from "components/Modal"
import VariantsGrid from "components/VariantsGrid"

import { useConfig } from "context/config"

const ColorVariantsModal = ({ onClose }) => {
  const {
    product_variations,
    current_wallpaper,
    setCurrentWallpaper,
    changeQueryUrl,
  } = useConfig()

  const formatted_product_variations = product_variations?.map(item => ({
    databaseId: item?.id,
    name: item?.label,
    featuredImage: {
      node: {
        localFile: {
          publicURL: item?.image,
        },
      },
    },
    ...item,
  }))

  const onSubmit = selected => {
    const find_selected = formatted_product_variations?.find(
      item => item?.databaseId === selected?.databaseId
    )

    setCurrentWallpaper(prev => ({
      ...prev,
      variation: find_selected,
    }))

    changeQueryUrl("variant", find_selected?.id)

    onClose()
  }

  return (
    <Modal
      header="Dostępne wersje kolorystyczne wzoru"
      btn_label="Wybierz wzór"
      onClose={onClose}
    >
      <div className="color-version-modal">
        <h3>Nazwa wzoru</h3>
        <VariantsGrid
          variant="large"
          items={formatted_product_variations?.reverse()}
          selected={current_wallpaper?.variation?.id}
          onSubmit={onSubmit}
        />
      </div>
    </Modal>
  )
}

export default ColorVariantsModal
