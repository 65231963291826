import "./styles.scss"
import "rc-slider/assets/index.css"

import React, { useState } from "react"
import { AnimatePresence } from "framer-motion"

import PatternsModal from "components/PatternsModal"
import ColorVariantsModal from "./components/ColorVariantsModal"
import TextureVariantsModal from "components/TextureVariantsModal"

import { useConfig } from "context/config"

import ChangeArrowsIcon from "assets/icons/config/ChangeArrowsIcon"
import OptionsMobile from "page_components/config/Content/components/ConfigPanel/components/OptionsMobile"

const ConfigPanel = ({ onReset }) => {
  const { current_wallpaper, changeQueryUrl, setCurrentWallpaper } = useConfig()

  const [color_variant_modal, setColorVariantModal] = useState(false)
  const [patterns_modal, setPatternsModal] = useState(false)
  const [texture_variant_modal, setTextureVariantModal] = useState(false)

  const options_list_data = [
    {
      label: "Nazwa wzoru*",
      value: current_wallpaper?.product?.name,
      // sub_value: "Nazwa kolekcji/rok",
      btn_label: "Zmień wzór",
      onClick: () => setPatternsModal(true),
    },
    {
      label: "Wersja kolorystyczna*",
      value: current_wallpaper?.variation?.label,
      btn_label: "Zmień kolor",
      onClick: () => setColorVariantModal(true),
    },
    {
      label: "Tekstura tapety*",
      value: current_wallpaper?.texture?.title,
      btn_label: "Zmień teksturę",
      onClick: () => setTextureVariantModal(true),
    },
    {
      label: "Materiały",
      value: "Winyl na flizelinie",
    },
    {
      label: "Wymiary",
      value: "30x60 cm",
    },
  ]

  const formatted_options_list = options_list_data?.filter(
    item => item?.label !== "Wymiary"
  )

  return (
    <div className="config-sample-panel">
      <div className="config-sample-panel__header">
        <h1>Próbka tapety</h1>
        <p>
          Zamawiając próbkę masz możliwość sprawdzenia rzeczywistych barw wzoru
          oraz zapoznania się z wybraną teskturą.
          <br />W próbce uchwyciliśmy fragment grafiki, starannie pomniejszony,
          by maksymalnie zaprezentować wybrany wzór.
        </p>
        <p>
          <strong>
            Wybierz wzór, wersję kolorystyczną oraz teksturę materiału.
          </strong>
        </p>
      </div>
      <OptionsMobile basic_data={formatted_options_list} />
      <div className="config-sample-panel__options">
        {options_list_data?.map(
          ({ label, value, sub_value, btn_label, onClick }, index) => (
            <div key={index} className="config-sample-panel__options__option">
              <strong>{label}</strong>
              <span>
                {value} {!!sub_value && <small>{sub_value}</small>}
              </span>
              {!!btn_label && (
                <button onClick={onClick}>
                  {btn_label} <ChangeArrowsIcon />
                </button>
              )}
            </div>
          )
        )}
      </div>
      <AnimatePresence mode="wait">
        {!!color_variant_modal && (
          <ColorVariantsModal onClose={() => setColorVariantModal(false)} />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {!!patterns_modal && (
          <PatternsModal onClose={() => setPatternsModal(false)} />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {!!texture_variant_modal && (
          <TextureVariantsModal
            current_wallpaper={current_wallpaper}
            setCurrentWallpaper={setCurrentWallpaper}
            onClose={() => setTextureVariantModal(false)}
            changeQueryUrl={changeQueryUrl}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default ConfigPanel
