import "./styles.scss"

import React, { useRef, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"

import Loader from "components/Loader"
import AddToCart from "components/AddToCart"
import ConfigPanel from "./components/ConfigPanel"
import ConfigPreview from "./components/ConfigPreview"
import IndividualOrderModal from "./components/ConfigPanel/components/IndividualOrderModal"

import { CUBIC_BEZIER } from "utils/constants"

import { useConfig } from "context/config"

const animation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    type: "tween",
    ease: CUBIC_BEZIER,
    duration: 0.3,
  },
}

const Content = () => {
  const ref = useRef()

  const { init_loading, current_wallpaper, final_price } = useConfig()

  const [individual_order_modal, setIndividualOrderModal] = useState(false)

  return (
    <section className="config-sample-content">
      <AnimatePresence mode="wait">
        {!!init_loading ? (
          <motion.div
            key="loading"
            className="config-sample-content__loader"
            {...animation}
          >
            <Loader color="primary" size="large" />
          </motion.div>
        ) : (
          <motion.div
            key="configurator"
            className="container-fluid"
            {...animation}
          >
            <div className="config-sample-content__wrapper">
              <div className="config-sample-content__left">
                <ConfigPreview ref={ref} final_price={final_price} />
              </div>
              <div className="config-sample-content__right">
                <ConfigPanel />
              </div>
            </div>

            <div className="config-sample-content__wrapper config-sample-content__wrapper--border">
              <div className="config-sample-content__left">
                <div className="config-sample-preview__items">
                  {!!current_wallpaper?.product?.samples?.[0]?.id && (
                    <div className="config-sample-preview__item">
                      <h3>Cena próbki wzoru</h3>
                      <div className="config-sample-preview__item__content">
                        <div className="config-sample-preview__item__content__info">
                          <h3>
                            {current_wallpaper?.product?.samples?.[0]?.price} zł
                          </h3>
                          <span>
                            Fragment tapety o wymiarach 30x60 cm, zgodny z
                            konfiguracją.
                          </span>
                        </div>
                        <AddToCart
                          databaseId={
                            current_wallpaper?.product?.samples?.[0]?.id
                          }
                          product={{
                            ...current_wallpaper?.product?.samples?.[0],
                            price:
                              current_wallpaper?.product?.samples?.[0]?.price?.toString(),
                          }}
                          extraData={{
                            texture:
                              current_wallpaper?.texture?.databaseId?.toString(),
                            variation: current_wallpaper?.variation?.label,
                            custom_price:
                              current_wallpaper?.product?.samples?.[0]?.price,
                            height: "60",
                            width: "30",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="config-sample-content__right"></div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {!!individual_order_modal && (
          <IndividualOrderModal
            onClose={() => setIndividualOrderModal(false)}
          />
        )}
      </AnimatePresence>
    </section>
  )
}

export default Content
