import "./styles.scss"
import React, { useState } from "react"

import axios from "axios"
import { navigate } from "gatsby"

import Input from "components/Input"
import Checkbox from "components/Checkbox"
import Button from "components/Button"

import Modal from "components/Modal"
import PlusIcon from "assets/icons/plus.svg"
import IcoInfo from "assets/icons/info.svg"

const IndividualOrderModal = ({ onClose }) => {
  const formID = "12834"
  const [send, setSend] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [legalFirst, setLegalFirst] = useState(false)
  const [free, setFree] = useState(null)
  const [files, setFiles] = useState([])

  const isBrowser = typeof window !== "undefined"
  const location = isBrowser ? window.location.href : null

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("firstName", name)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("message", message)
    formData.set("free", free)
    formData.set("legalFirst", legalFirst)
    formData.set("pageurl", location)
    files?.forEach(item => {
      formData.append("files", item)
    })
    formData.set("_wpcf7_unit_tag", '#wpcf7_unit_tag-'+formID)

    axios({
      method: "post",
      url: `https://cms.wallcraft.com.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        console.log("Submit success")
        navigate("/kontakt-potwierdzenie/")
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleInputImageChange = async files => {
    try {
      setFiles(prev => [...prev, ...files])
    } catch (err) {
      console.error(err)
    }
  }

  const handleImageRemove = async index => {
    let temp_files = [...files]

    temp_files.splice(index, 1)
    setFiles([...temp_files])
  }

  return (
    <Modal
      header="Opisz nam czego potrzebujesz"
      btn_label="Wybierz wzór"
      onClose={onClose}
    >
      <div className="individual-order-modal">
        <h3>Indywidualne zamówienie</h3>
        <form onSubmit={formSubmit} className="form">
          <div className="row">
            <div className="col-sm-6">
              <Input
                placeholder="Imię*"
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={e => setName(e.target.value)}
                required
              />
            </div>
            <div className="col-sm-6"></div>
            <div className="col-sm-6">
              <Input
                placeholder="Adres email*"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="col-sm-6">
              <Input
                placeholder="Numer telefonu*"
                type="number"
                id="phone"
                name="phone"
                value={phone}
                onChange={e => setPhone(e.target.value)}
                required
              />
            </div>
          </div>

          <Input
            placeholder="Treść wiadomosci*"
            type="textarea"
            id="message"
            name="message"
            value={message}
            onChange={e => setMessage(e.target.value)}
            rows="6"
            required
          />

          <div className="individual-order-modal__checkbox">
            <Checkbox
              name="free"
              checked={free}
              onChange={() => setFree(!free)}
              className="big-checkbox"
            >
              Dodaj darmowy zapas do wymiaru
            </Checkbox>
            <div>
              <img src={IcoInfo} alt="" />
              <strong>
                Bezpłatne dodanie 5 cm
                <br />
                na szerokość i 5 cm na wysokość.
              </strong>
            </div>
          </div>

          <div className="individual-order-modal__upload">
            <small>
              Dołącz zdjęcia pomieszczenia gdzie znajdować się będzie tapeta,
              bądź inne, które pomogą nam dostarczyć Ci najwyższej jakości
              produkt.
            </small>
            <div className="individual-order-modal__upload__btn">
              <label type="button" htmlFor="files">
                <input
                  type="file"
                  id="files"
                  name="files"
                  accept="image/png, image/jpeg"
                  multiple={true}
                  onChange={e => handleInputImageChange(e.target.files)}
                />
                Dodaj plik
                <img src={PlusIcon} alt="" />
              </label>
              <span>
                Plik w formacje JPG, PNG, PDF, ZIP. Maksymalnie do 5Mb.
              </span>
            </div>
            <div className="individual-order-modal__upload__items">
              {files?.map(({ name }, index) => (
                <div className="individual-order-modal__upload__items__item">
                  <span>{name}</span>
                  <button
                    type="button"
                    onClick={() => handleImageRemove(index)}
                  >
                    Usuń
                  </button>
                </div>
              ))}
            </div>
          </div>

          <Checkbox
            name="legalFirst"
            checked={legalFirst}
            onChange={() => setLegalFirst(!legalFirst)}
            required
          >
            <span>*</span> Wyrażam dobrowolną zgodę na przetwarzanie moich
            danych osobowych przez Wallcraft s.c. Robert Stodulski, Radosław
            Stodulski z siedzibą w Słupsku, ul. Kaszubska 45, REGON: 524037374
            (Administrator danych), w celu kontaktu bezpośredniego dotyczącego
            własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13
            ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27
            kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o
            przetwarzaniu przez nas danych, jak również o prawach osób, których
            dane dotyczą znajdują się w naszej Polityce Prywatności.
          </Checkbox>

          <p className="form__info">
            <span>*</span> Pola obowiązkowe
          </p>

          <div className="form__submit">
            <Button type="submit" disabled={!legalFirst}>
              {send === true ? "Wysyłanie.." : "Wyślij zapytanie"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default IndividualOrderModal
